<template>
    <el-container class="main-container">
        <el-container>
        <el-header>
            <Header />
        </el-header>
        <el-main>
            <div class="wrapper">
                <div class="preloader flex-column justify-content-center align-items-center">
                    <img class="animation__shake" src="../assets/logo.png" alt="AdminLTELogo" height="60" width="60">
                </div>

                <Navbar />
                <Sidebar />

                <router-view></router-view>

                <Footer />

                <aside class="control-sidebar control-sidebar-dark"></aside>
            </div>
        </el-main>
        </el-container>
    </el-container>
</template>

<script>
import Navbar from '../components/partials/Navbar.vue'
import Sidebar from '../components/partials/Sidebar.vue'
import Footer from '../components/partials/Footer.vue'

export default {
    name: 'App',
    components: { Navbar, Sidebar, Footer },
}
</script>