<template>
    <aside class="main-sidebar sidebar-light-primary elevation-4">
        <a href="index3.html" class="brand-link">
            <img :src="corrector.employer.logo" alt="Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
            <span class="brand-text font-weight-light">{{ corrector.employer.name }}</span>
        </a>

        <div class="sidebar">
            <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                <div class="image">
                    <img :src="corrector.avatar" class="img-circle elevation-2" alt="User Image">
                </div>
                <div class="info">
                    <router-link to="/perfil" class="d-block">{{ corrector.name }}</router-link>
                </div>
            </div>

            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li class="nav-item">
                        <router-link to="/dashboard" class="nav-link" :class="isActive('index')">
                            <font-awesome-icon icon="tachometer-alt" class="nav-icon" />
                            <p>Dashboard</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/corrigir" class="nav-link" :class="isActive('correct.index')">
                            <font-awesome-icon icon="file-alt" class="nav-icon" />
                            <p>Corrigir</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/corrigida" class="nav-link" :class="isActive('corrected.index')">
                            <font-awesome-icon icon="clipboard-check" class="nav-icon" />
                            <p>Corrigidas</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/chat" class="nav-link" :class="isActive('chat.index')">
                            <font-awesome-icon icon="comments" class="nav-icon" />
                            <p>Chat</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/suporte" class="nav-link" :class="isActive('support.index')">
                            <font-awesome-icon icon="life-ring" class="nav-icon" />
                            <p>Suporte</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link" >
                            <font-awesome-icon icon="chart-pie" class="nav-icon"/>
                            <p>Relatórios</p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link to="/relatorios/corrigidas" class="nav-link" :class="isActive('reports.corrected')">
                                    <font-awesome-icon icon="clipboard-check" class="nav-icon" />
                                    <p>Corrigidas</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <router-link to="/acompanhamentos" class="nav-link" :class="isActive('accompaniment.index')">
                            <font-awesome-icon icon="info-circle" class="nav-icon" />
                            <p>Acompanhamentos</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/editais" class="nav-link" :class="isActive('tests.index')">
                            <font-awesome-icon icon="archive" class="nav-icon" />
                            <p>Editais</p>
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </aside>
</template>

<script>
export default {
    name: 'Sidebar',
    data() {
        return {
            corrector: {
                name: '',
                avatar: '../../assets/avatar.png',
                employer: {
                    name: '',
                    logo: ''
                }
            }
        }
    },
    created() {
        this.corrector = JSON.parse(window.localStorage.getItem('corrector'))
    },
    methods: {
        
        isActive(routeName) {

            if(this.$route.matched.some(({ name }) => name === routeName)) {
                return 'active'
            }

            return ''
        }
    }
}
</script>