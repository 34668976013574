<template>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" href="#" role="button">
                    <font-awesome-icon icon="bars" />
                </a>
            </li>
        </ul>

        <ul class="navbar-nav ml-auto">
            <li class="nav-item dropdown">
                <a class="nav-link" data-toggle="dropdown" href="#">
                    <font-awesome-icon icon="comments" />
                    <span class="badge badge-danger navbar-badge">{{ messages.length }}</span>
                </a>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <div v-for="message in messages" :key="message.id">
                        <router-link :to="`/chat/${message.student.id}`" class="dropdown-item">
                            <div class="media">
                                <!-- <img src="../../assets/avatar.png" alt="User Avatar" class="img-size-50 mr-3 img-circle"> -->
                                <div class="media-body">
                                    <h3 class="dropdown-item-title">{{ message.student.name }}</h3>
                                    <p class="text-sm">{{ message.message }}</p>
                                    <p class="text-sm text-muted"><font-awesome-icon icon="clock" /> {{ message.created_at }}</p>
                                </div>
                            </div>
                        </router-link>
                        <div class="dropdown-divider"></div>
                    </div>
                    <router-link class="dropdown-item dropdown-footer" to="/chat">Ver todas as mensagens</router-link>
                </div>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link" data-toggle="dropdown" href="#">
                    <font-awesome-icon icon="bell" />
                    <span class="badge badge-warning navbar-badge">{{ totalNotifications }}</span>
                </a>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <span class="dropdown-item dropdown-header">{{ totalNotifications }} Notificações</span>
                    <div class="dropdown-divider"></div>
                    <router-link class="dropdown-item" to="/acompanhamentos">
                        <font-awesome-icon icon="info-circle" class="nav-icon" /> {{ accompanimentsCount }} acompanhamentos abertos
                    </router-link>
                    <!-- <div class="dropdown-divider"></div> -->
                    <!-- <a href="#" class="dropdown-item">
                        <i class="fas fa-users mr-2"></i> 8 friend requests
                        <span class="float-right text-muted text-sm">12 hours</span>
                    </a> -->
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-widget="fullscreen" href="#" role="button">
                    <font-awesome-icon icon="expand-arrows-alt" />
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
import axios from "axios";
import { NOTIFICATIONS_CHAT } from '../../constants/api'

export default {
    name: 'Navbar',
    data() {
        return {
            accompanimentsCount: 0,
            totalNotifications: 0,
            messages: []
        }
    },
    created() {
        axios.get(NOTIFICATIONS_CHAT, { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }).then(({ data }) => {
            this.messages = data
        });
        console.log(this.messages)
    }
}
</script>